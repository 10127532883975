function FOOTER_CONTENT() {
    return (
        <footer>
            <div class="footer_content">
                <h3>© Joven Ebio 2024, Powered by Amazon Web Services</h3>
            </div>
        </footer>
    );
  }
  
  export default FOOTER_CONTENT;