function HEADER_CONTENT() {
    return (
      <header>
        <div class="header_content">
          <img src="https://jovenebio.com/images/development_icon_colored.png" alt="Development Icon"></img>
          <h1>Professional Development Presentation Page</h1>
        </div>
      </header>
    );
  }
  
  export default HEADER_CONTENT;